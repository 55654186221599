import * as React from "react";
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import '../App.css'




function Terms() {


  return (
    <div>
      <Navbar />
      <div className="container">
        <br /> 
        <div className="row">
            <div className="col-12">
                <p>
                The service https://ritz.game is a site for informational purposes only.
                </p>
                <p>
                    RITZ is not part of any transaction on the blockchain networks underlying the 
                    protocol; we do not have possession, custody or control over any crypto assets appearing on the
                    interface; and we do not have possession, custody or control over any user's funds. Further, we do not store, send, or 
                    receive any crypto assets. You understand that when you interact with any RITZ smart contracts, you always retain control over your 
                    crypto assets. We do not have access to your private keys. 
                </p>
                <p>
                    The website found here is only an interface, that is open source, which helps interacting with smart contracts. 
                    Smart contracts in their nature are fully decentralized and we do not own any ownership powers over making you, or stopping you from 
                    interacting with them. Hence, it is your job to assess that you meet your jurisdiction's requirements to 
                    interact with the protocol. We highly recommend discussion over with lawyers such as <a href="https://www.kingsleynapley.co.uk/services/specialist-group/crypto-assets" target="_blank" rel="noreferrer">
                        Kingsley Napley
                    </a> to assess your circumstances. 
                </p>
                <p>
                From time to time, the Site or the Interface may be inaccessible or inoperable for any reason, including, but not limited to: (a) equipment malfunctions; (b) periodic maintenance procedures or repairs that RITZ or any of its suppliers or contractors may undertake from time to time; (c) causes beyond RITZ's control or that RITZ could not reasonably foresee; (d) disruptions and temporary or permanent unavailability of underlying blockchain infrastructure; or (e) unavailability of third-party service providers or external partners for any reason;
                </p>
                <p>
                We reserve the right to disable or modify access to the Site at any time in the event of any breach of these Terms, including, without limitation, if we reasonably believe any of your representations and warranties may be untrue or inaccurate, and we will not be liable to you for any losses or damages you may suffer as a result of or in connection with the Site or the Interface being inaccessible to you at any time or for any reason;
                </p>
                <p>
                You are solely responsible for your use of the Site or the Interface, including all of your transfers of digital assets; To the fullest not prohibited by Applicable Law, we owe no fiduciary duties or liabilities to you or any other party, and that to the extent any such duties or liabilities may exist at law or in equity, you hereby irrevocably disclaim, waive, and eliminate those duties and liabilities;
                </p>
                <p>
                You are solely responsible for reporting and paying any taxes applicable to your use of the Interface
                </p>

                <h5>Prohibited Uses</h5>
                <p>
                You agree not to engage in the prohibited uses set forth below. The specific activities set forth below are representative but not exhaustive. By using the Site or the Interface, you confirm that you will not do any of the following:
                </p>
                <p>
                Use the Site or Interface in any way that is, in our sole discretion, libelous, defamatory, profane, obscene, pornographic, sexually explicit, indecent, lewd, vulgar, suggestive, harassing, stalking, hateful, threatening, offensive, discriminatory, bigoted, abusive, inflammatory, fraudulent, deceptive, or otherwise objectionable or likely or intended to incite, threaten, facilitate, promote, or encourage hate, racial intolerance, or violent acts against others;
                </p>
                <p>
                Promote or facilitate illegal activities, including but not limited to money laundering, terrorist financing, tax evasion, buying or selling illegal drugs, contraband, counterfeit goods, or illegal weapons;
                </p>
                <p>
                Impersonate another user of the Site or the Interface or otherwise misrepresent yourself; or
                </p>
                <p>
                ngage in improper or abusive bet/trading practices, including but not limited to (a) any fraudulent act or scheme to defraud, deceive, trick, or mislead; (b) trading ahead of another user of the Site or the Interface or front-running; (c) fraudulent trading; (d) accommodation trading; (e) fictitious transactions; (f) pre-arranged or non-competitive transactions; or (g) cornering;
                </p>
                <h4>
                    Be Responsible 
                </h4>
                <p>
                If you observe the following rules, you can enjoy playing without any negative consequences:
                <ul>
                    <li>The game is a source of entertainment, not income.</li>
                    <li>Take regular breaks.</li>
                    <li>Determine in advance a monthly amount that you are willing to play with. Play only with money that you can afford to lose.</li>
                    <li>Only play when you are relaxed and can concentrate.</li>
                    <li>Determine in advance the amount that you can afford to lose.</li>
                    <li>Do not play when depressed or when under influence of alcohol or other substances.</li>
                </ul>

                </p>
            </div>
        </div>
        <br />
        <div className="row">
            <div className="col-12">
                <img src="/cmc.jpg" alt="coinmakretcap" style={{width:'100%', maxWidth:'600px', margin:'0 auto', display:'block'}} />
                <p style={{marginTop:"2rem", textAlign:'center'}}>CMC Ticket Number: 833266</p>
            </div>
        </div>

      </div>
      <Footer />
   </div>

  ); 
}

export default Terms;