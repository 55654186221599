import * as React from "react";
import * as ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { createGlobalStyle } from "styled-components";
// import Analytics from './Analytics';
// import Project from './Project';

import Homepage from './pages/Homepage';
import Game from './pages/Game';
import Terms from './pages/Terms';
import Lottery from './pages/Lottery'
import Coingecko from './pages/Coingecko'
import ConnectModal from './components/ConnectModal'
// import Token from './Token';
import { Toaster } from 'react-hot-toast';
import {config} from './wagmi/connectors'
import { WagmiConfig } from 'wagmi'


import './home.css'



window.Buffer = window.Buffer || require("buffer").Buffer;  



const GlobalStyles = createGlobalStyle`
  body {
    // font-family: 'OpenSans';
    height: 100%;
    background: #DDDEE0;
  }
`

function App() {
  return (
    <Router>
    <div>

    <GlobalStyles />
    <WagmiConfig config={config}>
      <ConnectModal />

          <Switch>  
          {/* <Route path="/project/:address">
          <Project />
          </Route>

          <Route path="/nftdrop">
            <NFTDROP />
          </Route> */}
          {/* <Route path="/token">
            <Token />
          </Route> */}
          <Route path="/terms">
          <Terms />
          </Route>
          
          <Route path="/raffle">
            <Lottery />
          </Route>

          <Route path="/game/:id">
            <Game />
          </Route>

          <Route path="/coingecko">
            <Coingecko />
          </Route>

          <Route path="/">
            <Homepage />
          </Route>
        </Switch>
        <Toaster />

    </WagmiConfig>

    </div>
    </Router>
  );
}


ReactDOM.render(<App />, document.getElementById("root"));
