import * as React from "react";
import { ethers } from 'ethers'
import {useAccount, usePrepareContractWrite,  useContractWrite, useNetwork, useWalletClient    } from 'wagmi'

import Web3 from 'web3'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import multicallabi from '../utils/abi_multicall.json'
import tokenabi from '../utils/token_abi.json'
import gamefeedabi from '../utils/gamefeedabi.json'
import mainabi from '../utils/mainabi.json'
import raffleabi from '../utils/raffle.json'
import routerabi from '../utils/router.json'
import { Interface } from '@ethersproject/abi'
import '../App.css'
import {
    useParams
  } from "react-router";
import toast from 'react-hot-toast';
import { SpinnerDiamond  } from 'spinners-react';
import Countdown from 'react-countdown-simple';


function Game() {
  
  

  const mainAddress = '0x62680BdF5846e3B409251f00eaD7f1388Ed2D43e' 

  let id = 0;

  const [choice, setChoice] = React.useState(0)
  const [type, setType] = React.useState(0)
  const [pause, setPause] = React.useState(false)
  const [roundn, setRoundn] = React.useState('0')
  const [jackpot, setJackpot] = React.useState('')
  const [contributions, setContributions] = React.useState('')
  const [disabled, setDisabled] = React.useState(false)


  const [busdamount, setBusdamount] = React.useState('')
  const [bnbamount, setBNBamount] = React.useState('')
  const [estimated, setEstimated] = React.useState('')
  const [finished, setFinished] = React.useState(false)

  // const context = useWeb3React();
  // const {
  //   library,
  //   chainId,
  //   account,
  //   active,
  // } = context;


  const { address, isConnected } = useAccount()
  const { chain } = useNetwork()
  const { data: walletClient } = useWalletClient()






  function numberWithCommas(n) {
    var parts=n.toString().split(".");
    return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
} 



  React.useEffect(() => {

    const checkBalances = async() => {
      const web3 = new Web3(
        // Replace YOUR-PROJECT-ID with a Project ID from your Infura Dashboard
        new Web3.providers.HttpProvider("https://bsc-dataseed.binance.org/")
      );        
        

      const contract2 = new web3.eth.Contract(raffleabi, mainAddress);
      const contributionss = await contract2.methods.independentData(roundn,address).call()
      setContributions(contributionss.totalEntries)
        // const balance = await contract.methods.balanceOf(address).call()
        // setBusdbalance(parseFloat(balance)/1e18)
        // const allowance = await contract.methods.allowance(address, mainAddress).call()
        // setAllowance(allowance)


      // library?.getBalance(address).then((result)=>{
      //   setEthbalance(result/1e18)
      // })


    }


    const checkBet = async() => {
      const web3 = new Web3(
        // Replace YOUR-PROJECT-ID with a Project ID from your Infura Dashboard
        new Web3.providers.HttpProvider("https://bsc-dataseed.binance.org/")
      );      
      
      const contract = new web3.eth.Contract(mainabi, mainAddress);
      // let t1Contribution = await contract.methods.contributionsT1(address, id).call()
      // let t2Contribution = await contract.methods.contributionsT2(address, id).call()
      // t1Contribution = t1Contribution / 1e18; 
      // t2Contribution = t2Contribution / 1e18;
      // setContrt1(t1Contribution.toFixed(2))
      // setContrt2(t2Contribution.toFixed(2))
      // let withdrew = await contract.methods.withdrewFunds(address,id).call()
      // setWithdrew(withdrew)

    }

    const checkJackpot = async() => {
      const web3 = new Web3(
        // Replace YOUR-PROJECT-ID with a Project ID from your Infura Dashboard
        new Web3.providers.HttpProvider("https://bsc-dataseed.binance.org/")
      );      
      
      const contract = new web3.eth.Contract(raffleabi, mainAddress);
      const paused = await contract.methods.pause().call()
      setPause(paused)
      const roundd = await contract.methods.roundN().call()
      setRoundn(roundd)

      const contract2 = new web3.eth.Contract(tokenabi, "0x4e89D67D88dBA3c42a8c37E1991c192384C75625");
      const jackpott = await contract2.methods.balanceOf(mainAddress).call()
      setJackpot(parseFloat(parseFloat(jackpott) / 1e18).toFixed(0))

      setFinished(true)
    }

    checkJackpot()

    if(address && walletClient){
      checkBalances()
      checkBet()
    }    

    const intervalId = setInterval(() => {  //assign interval to a variable to clear it.
      if(address && walletClient){
        checkBalances()
        // checkBet()
      }    
    }, 5000)
    return () => clearInterval(intervalId); //This is important

  }, [address, walletClient, roundn])





  function filter_letters_and_symbols(evt){

    var hold = String.fromCharCode(evt.which);
  
    if((/[a-z A-Z*!@#$%^&*()_/[\]}=+><{?":;,'"|]/.test(hold))){
  
      evt.preventDefault();
  
    }
  }



  const enterRaffle = async(amount) => {

    const enterNow = async() => {
    setDisabled(true)
    const web3 = new Web3(walletClient)
    const contract = new web3.eth.Contract(raffleabi, mainAddress);
    let gasProvider = new ethers.providers.Web3Provider(walletClient);
    const gasPrice = await gasProvider.getGasPrice()

    let amountToPass = parseFloat(amount)*1e18;

    await contract.methods.participateInGame().send({
      from: address,
      gasPrice:gasPrice,
      value: amountToPass
    })
    setDisabled(false)
    }

    toast.promise(
      enterNow(),
      {
        loading: 'Betting...',
        success: <b>Success!</b>,
        error: () => {
          // console.log(data);
          setDisabled(false)
          return  <b>Something went wrong.</b>;
        },
      }
    );
  }

  return (
    <div style={{background:"url('/bg_raffle.png')"}}>
      <Navbar />
      <div className="container">
        <br /> 
        <div>
          <h4 style={{textAlign:'center', color:'navajowhite', fontWeight:'bold', fontSize:'3rem'}}>Super Jackpot</h4>
          <h1 style={{textAlign:'center', fontFamily:"Poppins", fontWeight:'bold',color: "#FFE500",background: "#09254A", width: "100%",maxWidth: "300px",margin: "0 auto",borderRadius: "25px"}}>{numberWithCommas(jackpot)} RITZ</h1>
        
          {finished ? <>
            <div className="row">
            {/* HERE */}
            <div className="col-12 col-md-6" style={{marginTop:"1rem",}}>
            <video width="100%" height="500" autoPlay muted loop style={{borderRadius:'50%'}} >
      <source src="/lottery.mp4" type="video/mp4"/>
     </video>
     
          {/* <div className="card" style={{width:'100%', margin:'0 auto', padding:'0px'}}>
            <div className="card-header" style={{background:'#F1F1F1', height:'50px'}}>
            <p style={{color:'grey'}}>
                
            <span class="badge badge-danger" style={{background:'orangered', marginRight:'1rem'}}>HOT</span>

                </p>
            </div>
            <div className="card-body">
              <div className="row" style={{padding:'0px', background:'white'}}>
                <div className="col-12">

                </div>
                
                

              </div>
              
              </div>
              
          </div> */}
        </div>

            {/* HERE */}


            <div className="col-12 col-md-6" style={{marginTop:"1rem"}}>
            <div className="card" style={{width:'100%', margin:'0 auto', padding:'0px'}}>
            <div className="card-header" style={{background:'black', height:'50px'}}>
            <p style={{color:'lightskyblue'}}>
                ENTER RAFFLE
                {pause == false ? <span class="badge badge-success" style={{marginLeft:'1rem'}}>LIVE</span> : <span class="badge badge-danger" style={{marginLeft:'1rem'}}>BETS CLOSED</span>}

            </p>
            </div>
            <div className="card-body" style={{background:'darkblue'}}>
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-4 col-md-4" style={{textAlign:'center'}}>
                                <button className="btn btn-primary superhover" disabled={disabled} onClick={() => enterRaffle(0.01)} style={{background:'#000080', color:'white', fontFamily:'Poppins', width:'100%'}}>
                                <h3 style={{marginTop:'1rem'}}>1 ticket</h3>
                                <p style={{fontWeight:'bold'}}>0.01 BNB</p>
                                </button>
                            </div>
                            <div className="col-4 col-md-4" style={{textAlign:'center'}}>
                            <button className="btn btn-primary superhover" disabled={disabled} onClick={() => enterRaffle(0.03)} style={{background:'#000080', color:'white', fontFamily:'Poppins', width:'100%'}}>
                                <h3 style={{marginTop:'1rem'}}>3 ticket</h3>
                                <p style={{fontWeight:'bold'}}>0.03 BNB</p>
                                </button>
                            </div>
                            <div className="col-4 col-md-4" style={{textAlign:'center'}}>
                            <button className="btn btn-primary superhover" disabled={disabled} onClick={() => enterRaffle(0.05)} style={{background:'#000080', color:'white', fontFamily:'Poppins', width:'100%'}}>
                                <h3 style={{marginTop:'1rem'}}>5 ticket</h3>
                                <p style={{fontWeight:'bold'}}>0.05 BNB</p>
                                </button>
                            </div>
                        </div>
                        <div className="row" style={{marginTop:'1rem'}}>
                            <div className="col-4 col-md-4" style={{textAlign:'center'}}>
                            <button className="btn btn-primary superhover" disabled={disabled} onClick={() => enterRaffle(0.1)} style={{background:'#000080', color:'white', fontFamily:'Poppins', width:'100%'}}>
                                <h3 style={{marginTop:'1rem'}}>10 ticket</h3>
                                <p style={{fontWeight:'bold'}}>0.1 BNB</p>
                                </button>
                            </div>
                            <div className="col-4 col-md-4" style={{textAlign:'center'}}>
                            <button className="btn btn-primary superhover" disabled={disabled} onClick={() => enterRaffle(0.25)} style={{background:'#000080', color:'white', fontFamily:'Poppins', width:'100%'}}>
                                <h3 style={{marginTop:'1rem'}}>25 ticket</h3>
                                <p style={{fontWeight:'bold'}}>0.25 BNB</p>
                                </button>
                            </div>
                            <div className="col-4 col-md-4" style={{textAlign:'center'}}>
                            <button className="btn btn-primary superhover" disabled={disabled} onClick={() => enterRaffle(0.5)} style={{background:'#000080', color:'white', fontFamily:'Poppins', width:'100%'}}>
                                <h3 style={{marginTop:'1rem'}}>50 ticket</h3>
                                <p style={{fontWeight:'bold'}}>0.5 BNB</p>
                                </button>
                            </div>
                        </div>
                    </div>
                   

                </div>
            </div>
            </div>
            </div>
            {isConnected ?             <div className="col-12">
                              <p style={{color:'white', marginTop:'1rem', background:'darkgreen', width:'100%', maxWidth:"250px", margin:'0 auto', display:'block', borderRadius:'25px', padding:'5px',textAlign:'center'}}>You have {contributions} entries for this round</p>
                            </div> : null }

            <div className="col-12 col-md-12" style={{marginTop:"8rem"}}>



            </div>

          </div>
          </> : 
          <SpinnerDiamond size={66} thickness={159} speed={99} color="rgba(131, 172, 57, 1)" secondaryColor="rgba(65, 172, 57, 0.66)" style={{margin:'0 auto', display:'block', marginBottom:"60%"}} />          }

          
        </div>
      </div>
      <Footer />
   </div>

  ); 
}

export default Game;