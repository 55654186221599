import * as React from "react";
import 'react-toastify/dist/ReactToastify.css';
import Web3 from 'web3'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import multicallabi from '../utils/abi_multicall.json'
import gamefeedabi from '../utils/gamefeedabi.json'
import mainabi from '../utils/mainabi.json'
import { Interface } from '@ethersproject/abi'
import '../App.css'
import Countdown from 'react-countdown-simple';



function Coingecko() {
  
  


  return (
    <div>
      <Navbar />
      <div className="container">
        <br /> 
        <div>
        <img src="/coingecko.jpg" alt="coingecko" style={{margin:'0 auto', display:'block', width:'100%', maxWidth:"700px"}} />

        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <Footer />
   </div>

  ); 
}

export default Coingecko;