

import * as React from "react";



import 'react-toastify/dist/ReactToastify.css';
import {useAccount, useNetwork   } from 'wagmi'






function NavBar() {
 
  const { address, connector, isConnected } = useAccount()
  const { chain } = useNetwork()





  const truncateRegex = /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/;

  const truncateEthAddress = (address) => {
    if(address !== undefined){
    const match = address.match(truncateRegex);
    if (!match) return address;
    return `${match[1]}…${match[2]}`;
    }
  };

  return (
<nav class="navbar navbar-expand-lg navbar-dark" style={{background:'#303030', minHeight:'80px'}}>

  
  <a class="navbar-brand d-none d-sm-block" href="/" style={{marginLeft:"1%"}}> 
  
  <img src="/logo.svg" style={{width:'100%', maxWidth:'156px', height:"100%", maxHeight:"80px"}} alt="logo" /> 
  
  
  </a>

  <a class="navbar-brand d-block d-sm-none" href="/" style={{fontSize:'0.9rem'}}> 
  
  <img src="/logo.svg" style={{width:'100%', maxWidth:'156px', height:"100%", maxHeight:"80px"}} alt="logo" /> 
  
  
  </a>
  

  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a class="nav-link" href="/">Home </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/raffle">Sunday Raffle</a>
      </li>
      
      <li class="nav-item">
        <a class="nav-link" href="https://claim.ritz.game/">Claim</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" target="_blank" rel="noreferrer" href="/RITZ.pdf">Whitepaper</a>
      </li>

      
      {/* <li class="nav-item">
        <a class="nav-link" href="https://stake.mintstarter.app">Staking</a>
      </li> */}
      {/* <li class="nav-item">
        <a class="nav-link" target="_blank" rel="noreferrer" href="https://docs.csgocryptobets.io/">About</a>
      </li> */}
      {/* <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Dropdown
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" href="#">Action</a>
          <a class="dropdown-item" href="#">Another action</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="#">Something else here</a>
        </div>
      </li> */}

    </ul>
    <div class="d-flex" style={{minWidth:'120px'}}>
        {address && isConnected ?         <button class="btn btn-text"  data-toggle="modal" data-target="#exampleModal" style={{border:'2px solid #ff9200', color:'white', width:'100%'}} type="button">  <span> {truncateEthAddress(address)} </span></button>
        :         <button class="btn btn-text"  data-toggle="modal" data-target="#exampleModal" style={{border:'2px solid #ff9200', color:'white', width:'98%'}} type="button"> <img alt="bsc" src="https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/info/logo.png" width="20px" /> <span> Connect </span></button>
      }
    </div>
    <div class="d-flex">
    <a href="https://twitter.com/ritzsportsbet" style={{marginLeft:'1rem'}} target="_blank" rel="noreferrer">
    <img src="/twitter.png" width="40px" />
    </a>
    <a href="https://t.me/ritzsportsbet" style={{marginLeft:'1rem'}} target="_blank" rel="noreferrer">
    <img src="/telegram.png" width="40px" />
    </a>
    </div>
  </div>


</nav>


  ); 
}

export default NavBar;