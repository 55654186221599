// import { WagmiConfig, createConfig, configureChains } from 'wagmi'
// // import {  polygon } from 'wagmi/chains'

// // import { alchemyProvider } from 'wagmi/providers/alchemy'
// import { jsonRpcProvider } from '@wagmi/core/providers/jsonRpc'

// import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
// import { InjectedConnector } from 'wagmi/connectors/injected'
// import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
// import { WalletConnectConnector } from '@wagmi/core/connectors/walletConnect'

// // import { publicProvider } from 'wagmi/providers/public'

// // import { Chain } from 'wagmi'
 
//  const bscChain = {
//   id: 56,
//   name: 'bsc',
//   network: 'BSC',
//   nativeCurrency: {
//     decimals: 18,
//     name: 'BNB',
//     symbol: 'BNB',
//   },
//   rpcUrls: {
//     public: { http: ['https://rpc.ankr.com/bsc'] },
//     default: { http: ['https://rpc.ankr.com/bsc'] }
//   },
//   blockExplorers: {
//     default: { name: 'Explorer', url: 'https://bscscan.com/' },
//   },
// } 

// // Configure chains & providers with the Alchemy provider.
// // Two popular providers are Alchemy (alchemy.com) and Infura (infura.io)
// const { chains, publicClient, webSocketPublicClient } = configureChains(
//   [bscChain],
//   [
//     jsonRpcProvider({
//       rpc: (chain) => ({
//         http: `https://rpc.ankr.com/bsc`,
//       }),
//     }),
//   ]
//   ,)

// // Set up wagmi config
// export const config = createConfig({
//   autoConnect: true,
//   connectors: [
//     new MetaMaskConnector({ chains }),
//     new CoinbaseWalletConnector({
//       chains,
//       options: {
//         appName: 'wagmi',
//       },
//     }),
//     new WalletConnectConnector({
//       chains,
//       options: {
//         showQrModal: true,
//         projectId: 'f4737f643681521611cbc61dda826df7',
//         metadata: {
//           name: 'Ritz',
//           description: 'Ritz game, decentralized gambling',
//           // url: 'https://wagmi.sh',
//           // icons: ['https://wagmi.sh/icon.png'],
//         },
//       },
//     }),
//     new InjectedConnector({
//       chains,
//       options: {
//         name: 'Injected',
//         shimDisconnect: true,
//       },
//     })
//   ],
//   publicClient,
//   webSocketPublicClient,
// })



// imported
import { WagmiConfig, createConfig, configureChains } from 'wagmi'
// import {  polygon } from 'wagmi/chains'

import { bsc } from 'wagmi/chains'

// import { alchemyProvider } from 'wagmi/providers/alchemy'
import { jsonRpcProvider } from '@wagmi/core/providers/jsonRpc'

import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from '@wagmi/core/connectors/walletConnect'

// import { publicProvider } from 'wagmi/providers/public'

// import { Chain } from 'wagmi'
 
 const polygonChain = {
  id: 56,
  name: 'bsc',
  network: 'BSC',
  nativeCurrency: {
    decimals: 18,
    name: 'BNB',
    symbol: 'BNB',
  },
  rpcUrls: {
    public: { http: ['https://rpc.ankr.com/bsc'] },
    default: { http: ['https://rpc.ankr.com/bsc'] }
  },
  blockExplorers: {
    default: { name: 'Explorer', url: 'https://bscscan.com/' },
  },
} 

// Configure chains & providers with the Alchemy provider.
// Two popular providers are Alchemy (alchemy.com) and Infura (infura.io)
const { chains, publicClient, webSocketPublicClient } = configureChains(
  [polygonChain],
  [
    jsonRpcProvider({
      rpc: (chain) => ({
        http: `https://rpc.ankr.com/bsc`,
      }),
    }),
  ]
  ,)

// Set up wagmi config
export const config = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: 'wagmi',
      },
    }),
    new WalletConnectConnector({
      chains,
      options: {
        showQrModal: true,
        projectId: 'f4737f643681521611cbc61dda826df7',
      },
    }),
    new InjectedConnector({
      chains,
      options: {
        name: 'Injected',
        shimDisconnect: true,
      },
    })
  ],
  publicClient,
  webSocketPublicClient,
})

