import * as React from "react";
import 'react-toastify/dist/ReactToastify.css';
import Web3 from 'web3'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import multicallabi from '../utils/abi_multicall.json'
import gamefeedabi from '../utils/gamefeedabi.json'
import mainabi from '../utils/mainabi.json'
import { Interface } from '@ethersproject/abi'
import '../App.css'
import Countdown from 'react-countdown-simple';



function Homepage() {
  
  

  const [display, setDisplay] = React.useState([])

  const mainAddress = '0x186d6f0b9DCd4C6b19c3333fe3cb152DdA0Ea54F' // upgrade to 0x076b104f78806Cb501303fe8A7B1f41E1d5477b6
  const gameFeed = '0xC8c0104EC9FaA739D9813e4B67ED8eB815b15679'

  const renderer = ({ days, hours, minutes, seconds }) =>
  <div style={{color:'gray', fontWeight:'bold'}} >{days}D {hours}H {minutes}M {seconds}S</div>

  React.useEffect(() => {

    const getData = async() => {
      const web3 = new Web3(
        // Replace YOUR-PROJECT-ID with a Project ID from your Infura Dashboard
        new Web3.providers.HttpProvider("https://bsc-dataseed.binance.org/")
      );
  
      const contract = new web3.eth.Contract(gamefeedabi, gameFeed);

      const id = await contract.methods.id().call();
      let calls = []
      let calls2 = []
      let calls3 = []

      for(let i=0; i<id; i++){
        calls.push({
          address: gameFeed,
          name: 'games',
          params: [i],
        })
        calls2.push({
          address: mainAddress,
          name: 't1raise',
          params: [i],
        })
        calls3.push({
          address: mainAddress,
          name: 't2raise',
          params: [i],
        })
      }
      // calls = calls.reverse()
      // calls2 = calls2.reverse()
      // calls3 =  calls3.reverse()
      const multicallResults = await bscMulticall(gamefeedabi, calls)
      const multicallResults2 = await bscMulticall(mainabi, calls2)
      const multicallResults3 = await bscMulticall(mainabi, calls3)


      const toDisplay = []

      for(let i = multicallResults.length - 1; i >= 0; i--){

        let islive = false; 
        if(Date.now() > new Date(parseFloat(multicallResults[i].thetime) * 1000)){
          islive = true;
        }

        const actualTime = new Date(parseFloat(multicallResults[i].thetime) *1000)
        const theName = multicallResults[i].name.split(',')
        let total = parseFloat(multicallResults2[i]) + parseFloat(multicallResults3[i])
        let t1Pc = parseFloat(multicallResults2[i]) / total * 100
        let t2Pc = parseFloat(multicallResults3[i]) / total * 100
        const winner = multicallResults[i].result;

        if(total == 0){
          t1Pc = 50
          t2Pc = 50
        }



        toDisplay.push(
          <div className="col-12 col-md-12" style={{marginBottom:"1rem"}}>
          <a href={`/game/${i}`} style={{textDecoration:'none', color:'black'}}>
          <div className="card" style={{width:'100%', maxWidth:"500px", margin:'0 auto', padding:'0px'}}>
            <div className="card-header" style={{background:'#F1F1F1', height:'50px'}}>
            <p style={{color:'grey'}}>
            {islive && multicallResults[i].result == '0' ? <span class="badge badge-secondary" style={{background:'orangered', marginRight:'1rem'}}>LIVE</span> : 
               multicallResults[i].result != '0' ? <span class="badge badge-secondary" style={{marginRight:'1rem'}}>GAME ENDED</span> :
              null}                {actualTime.toDateString()}, {actualTime.toLocaleTimeString()}
                              {islive == false ? <div style={{float:'right'}}> <Countdown targetDate={actualTime} renderer={renderer} /> </div> : null}
                </p>
            </div>
            <div className="card-body">
              <div className="row" style={{padding:'10px', background:'white'}}>
                <div className="col-12">
                  <p style={{textAlign:'center', fontWeight:'bold'}}>
                  {theName[0]} 
                  <p style={{color:'mediumpurple'}}>
                  {theName[1]}
                  <p style={{color:'mediumpurple'}}>
                  <img src={`${theName[1]}.png`} style={{width:"100%", maxWidth:"150px"}} alt="match type" />
                  </p>
                  </p>
                  </p>
                </div>
                <div className="col-4" style={{textAlign:'right'}}>

                  <img src={`/teams/${(multicallResults[i].t1).replace(/\s+$/, '')}.png`} width="40px" alt="tbd" style={{margin:'0 auto', display:'block'}} />
                  {winner == '1' ? <img src="/win.svg" width="30px" alt="winner" style={{position:'absolute', top:'0', right:'0'}} /> : null}
                 <p  style={{textAlign:'center'}}> {multicallResults[i].t1}</p>
                 <h4 style={{textAlign:'center', color:'#009900', fontWeight:'bold'}}>
                  {t1Pc.toFixed(2)}%
                 </h4>
                 <p style={{textAlign:'center', color:'darkorange'}}>
                    x{(100 / parseFloat(t1Pc)).toFixed(2)}
                  </p>
                </div>
                <div className="col-4">
                  <p style={{background:'#F1F1F1', borderRadius:"100%", width:'3.43em', height:"3.43em", display:'flex', justifyContent:'center', alignItems:'center', margin:'0 auto'}}>{theName[2]}</p>
                </div>
                <div className="col-4" >
                  <img src={`/teams/${(multicallResults[i].t2).replace(/\s+$/, '')}.png`} width="40px" alt="tbd" style={{margin:'0 auto', display:'block'}} />
                  {winner == '2' ? <img src="/win.svg" width="30px" alt="winner" style={{position:'absolute', top:'0', left:'0'}} /> : null}
                 <p style={{textAlign:'center'}}> {multicallResults[i].t2}
                  {/* <sup><span class="badge badge-primary">W</span></sup> */}
                  </p>
                 <h4 style={{textAlign:'center', color:'#009900', fontWeight:'bold'}}>
                  {t2Pc.toFixed(2)}%
                 </h4>
                 <p style={{textAlign:'center', color:'darkorange'}}>
                    x{(100 / parseFloat(t2Pc)).toFixed(2)}
                  </p>
                </div>
              </div>
              </div>
          </div>
          </a>
        </div>
        )
      }
      setDisplay(toDisplay)



    }

    getData()


  }, [])


  const multicallHelper = async (abi,calls) => {
    const itf = new Interface(abi)
  
    const calldata = calls.map((call) => ({
      target: call.address.toLowerCase(),
      callData: itf.encodeFunctionData(call.name, call.params),
    }))

    return calldata 

  }

  const bscMulticall = async (TOKENABI, ethCalls) => {
    const web3 = new Web3(
      // Replace YOUR-PROJECT-ID with a Project ID from your Infura Dashboard
      new Web3.providers.HttpProvider("https://bsc-dataseed.binance.org/")
    );

    const ethMulticall = new web3.eth.Contract(multicallabi, '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B');
    let aggregatedData = await multicallHelper(TOKENABI, ethCalls)
    const itf = new Interface(TOKENABI)

    let {returnData} = await ethMulticall.methods.aggregate(aggregatedData).call()
    const balanceTokens = returnData.map((call, i) => itf.decodeFunctionResult(ethCalls[i].name, call))
    return balanceTokens;
  }

  return (
    <div>
      <Navbar />
      <div className="container">
        <br /> 
        <div>
          <h4 style={{textAlign:'center', color:'grey', fontWeight:'bold'}}>Place a Bet</h4>
        
          
          <div className="row">

            {display}

            
            

          </div>

        </div>
      </div>
      <Footer />
   </div>

  ); 
}

export default Homepage;