import * as React from "react";
import { ethers } from 'ethers'
import {useAccount, usePrepareContractWrite,  useContractWrite, useNetwork, useWalletClient    } from 'wagmi'

import Web3 from 'web3'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import multicallabi from '../utils/abi_multicall.json'
import tokenabi from '../utils/token_abi.json'
import gamefeedabi from '../utils/gamefeedabi.json'
import mainabi from '../utils/mainabi.json'
import routerabi from '../utils/router.json'
import { Interface } from '@ethersproject/abi'
import '../App.css'
import {
    useParams
  } from "react-router";
import toast from 'react-hot-toast';
import { SpinnerDiamond  } from 'spinners-react';
import Countdown from 'react-countdown-simple';


function Game() {
  
  

  const [display, setDisplay] = React.useState([])

  const mainAddress = '0x186d6f0b9DCd4C6b19c3333fe3cb152DdA0Ea54F' // 0xCe8eEC93427278162b62Dfa9b7456b0FC3C12e43 0xedAe99cfE4c8Bd1B3098400d04D0f7968E2CC05D upgrade to 0x076b104f78806Cb501303fe8A7B1f41E1d5477b6
  const gameFeed = '0xC8c0104EC9FaA739D9813e4B67ED8eB815b15679'

  let { id } = useParams();

  const [choice, setChoice] = React.useState(0)
  const [type, setType] = React.useState(0)

  const [t1n, setT1n] = React.useState('')
  const [t2n, setT2n] = React.useState('')
  const [ethbalance, setEthbalance] = React.useState('0')
  const [busdbalance, setBusdbalance] = React.useState('0')
  const [banusbalance, setBanusbalance] = React.useState('0')
  const [ritzbalance, setRitzbalance] = React.useState('0')

  const [busdamount, setBusdamount] = React.useState('')
  const [bnbamount, setBNBamount] = React.useState('')
  const [estimated, setEstimated] = React.useState('')
  const [estimated2, setEstimated2] = React.useState(0)
  const [estimated3, setEstimated3] = React.useState(0)

  const [finished, setFinished] = React.useState(false)
  const [allowance, setAllowance] = React.useState('0')
  const [allowance2, setAllowance2] = React.useState('0')
  const [allowance3, setAllowance3] = React.useState('0')

  const [live, setLive] = React.useState(false)
  const [contrT1, setContrt1] = React.useState('')
  const [contrT2, setContrt2] = React.useState('')

  const [t1name, setT1name] = React.useState('')
  const [t2name, setT2name] = React.useState('')
  const [x1odds, setX1odds] = React.useState('')
  const [x2odds, setX2odds] = React.useState('')
  const [winnerr, setWinner] = React.useState('0')
  const [withdrew, setWithdrew] = React.useState(false)

  // const context = useWeb3React();
  // const {
  //   library,
  //   chainId,
  //   account,
  //   active,
  // } = context;


  const { address, isConnected } = useAccount()
  const { chain } = useNetwork()
  const { data: walletClient } = useWalletClient()


  const setTeam = async(n, t) => {
    setChoice(n)
    toast.success(`${t} successfully chosen`)
  }

  const renderer = ({ days, hours, minutes, seconds }) =>
  <div style={{color:'gray', fontWeight:'bold'}} >{days}D {hours}H {minutes}M {seconds}S</div>


  React.useEffect(() => {

    const getData = async() => {

      let calls = [{
        address: gameFeed,
        name: 'games',
        params: [id],
      }]
      let calls2 = [{
        address: mainAddress,
        name: 't1raise',
        params: [id],
      }]
      let calls3 = [{
        address: mainAddress,
        name: 't2raise',
        params: [id],
      }]

      const multicallResults = await bscMulticall(gamefeedabi, calls)
      const multicallResults2 = await bscMulticall(mainabi, calls2)
      const multicallResults3 = await bscMulticall(mainabi, calls3)




      const toDisplay = []

      for(let i=0; i<multicallResults.length; i++){
        
        let islive = false; 
        if(Date.now() > new Date(parseFloat(multicallResults[i].thetime) * 1000)){
          setLive(true)
          islive = true;
        }

        const actualTime = new Date(parseFloat(multicallResults[i].thetime) *1000)
        const theName = multicallResults[i].name.split(',')
        let total = parseFloat(multicallResults2[i]) + parseFloat(multicallResults3[i])
        let t1Pc = parseFloat(multicallResults2[i]) / total * 100
        let t2Pc = parseFloat(multicallResults3[i]) / total * 100


        const winner = multicallResults[i].result;
        setWinner(winner)

        if(total == 0){
          t1Pc = 50
          t2Pc = 50
        }
        setT1n(multicallResults[i].t1)
        setT2n(multicallResults[i].t2)

        setT1name(multicallResults[i].t1)
        setT2name(multicallResults[i].t2)

        setX1odds((100 / parseFloat(t1Pc)))
        setX2odds((100 / parseFloat(t2Pc)))
        

        toDisplay.push(
          <div className="col-12 col-md-6" style={{marginTop:"1rem"}}>
          <div className="card" style={{width:'100%', margin:'0 auto', padding:'0px'}}>
            <div className="card-header" style={{background:'#F1F1F1', height:'50px'}}>
            <p style={{color:'grey'}}>
              {islive && winner == '0' ? <span class="badge badge-secondary" style={{background:'orangered', marginRight:'1rem'}}>LIVE</span> : 
               winner != '0' ? <span class="badge badge-secondary" style={{marginRight:'1rem'}}>GAME ENDED</span> :
              null}
                {actualTime.toDateString()}, {actualTime.toLocaleTimeString()}   
                {islive == false ? <div style={{float:'right'}}> <Countdown targetDate={actualTime} renderer={renderer} /> </div> : null}

                </p>
            </div>
            <div className="card-body">
              <div className="row" style={{padding:'10px', background:'white'}}>
                <div className="col-12">
                  <p style={{textAlign:'center', fontWeight:'bold'}}>
                  {theName[0]} 
                  <p style={{color:'mediumpurple'}}>
                  {theName[1]}
                  </p>
                  </p>
                </div>
                
                <div className="col-4" style={{textAlign:'right'}}>
                  
                  <img src={`/teams/${(multicallResults[i].t1).replace(/\s+$/, '')}.png`} width="40px" alt="tbd" style={{margin:'0 auto', display:'block'}} />
                  {winner == '1' ? <img src="/win.svg" width="30px" alt="winner" style={{position:'absolute', top:'0', right:'0'}} /> : null}

                 <p  style={{textAlign:'center'}}> {multicallResults[i].t1}</p>
                 <h4 style={{textAlign:'center', color:'#009900', fontWeight:'bold'}}>
                  {t1Pc.toFixed(2)}%
                 </h4>
                 <p style={{textAlign:'center', color:'darkorange'}}>
                    x{(100 / parseFloat(t1Pc)).toFixed(2)}
                  </p>
                </div>
                <div className="col-4">
                  <p style={{background:'#F1F1F1', borderRadius:"100%", width:'3.43em', height:"3.43em", display:'flex', justifyContent:'center', alignItems:'center', margin:'0 auto'}}>{theName[2]}</p>
                </div>
                <div className="col-4" >
                  
                  <img src={`/teams/${(multicallResults[i].t2).replace(/\s+$/, '')}.png`} width="40px" alt="tbd" style={{margin:'0 auto', display:'block'}} />
                  {winner == '2' ? <img src="/win.svg" width="30px" alt="winner" style={{position:'absolute', top:'0', left:'0'}} /> : null}

                 <p style={{textAlign:'center'}}> {multicallResults[i].t2}
                  {/* <sup><span class="badge badge-primary">W</span></sup> */}
                  </p>
                 <h4 style={{textAlign:'center', color:'#009900', fontWeight:'bold'}}>
                  {t2Pc.toFixed(2)}%
                 </h4>
                 <p style={{textAlign:'center', color:'darkorange'}}>
                    x{(100 / parseFloat(t2Pc)).toFixed(2)}
                  </p>
                </div>
                {winner == 0 && parseFloat(total) > 0 ? 
              <div className="col-12" style={{color:'darkgray', fontWeight:'bold', textAlign:'center'}}>
              Current Pool Size {numberWithCommas((total / 1e18).toFixed(2))} BUSD
            </div> : null  
              }
                

              </div>
              
              </div>
              
          </div>
        </div>
        )
      }
      setDisplay(toDisplay)
      setFinished(true)


    }



    getData()

        const intervalId = setInterval(() => {  //assign interval to a variable to clear it.
      getData()
    }, 5000)
    return () => clearInterval(intervalId); //This is important

  }, [])

  function numberWithCommas(n) {
    var parts=n.toString().split(".");
    return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
}

  React.useEffect(() => {

    const checkBalances = async() => {
      const web3 = new Web3(
        // Replace YOUR-PROJECT-ID with a Project ID from your Infura Dashboard
        new Web3.providers.HttpProvider("https://bsc-dataseed.binance.org/")
      );        
        
      const contract = new web3.eth.Contract(tokenabi, "0x55d398326f99059ff775485246999027b3197955");
        const balance = await contract.methods.balanceOf(address).call()
        setBusdbalance(parseFloat(balance)/1e18)
        const allowance = await contract.methods.allowance(address, mainAddress).call()
        setAllowance(allowance)
        var ethBBalance = await web3.eth.getBalance(address); //Will give value in.
        setEthbalance(parseFloat(ethBBalance)/1e18)

        const contract2 = new web3.eth.Contract(tokenabi, "0x98999aa1b0d17fb832fd509e13b67fe506513a6d");
        const balance2 = await contract2.methods.balanceOf(address).call()
        setBanusbalance(parseFloat(balance2)/1e18)
        const allowance2 = await contract2.methods.allowance(address, mainAddress).call()
        setAllowance2(allowance2)


        const contract3 = new web3.eth.Contract(tokenabi, "0x4e89d67d88dba3c42a8c37e1991c192384c75625");
        const balance3 = await contract3.methods.balanceOf(address).call()
        setRitzbalance(parseFloat(balance3)/1e18)
        const allowance3 = await contract3.methods.allowance(address, mainAddress).call()
        setAllowance3(allowance3)

      // library?.getBalance(address).then((result)=>{
      //   setEthbalance(result/1e18)
      // })


    }


    const checkBet = async() => {
      const web3 = new Web3(
        // Replace YOUR-PROJECT-ID with a Project ID from your Infura Dashboard
        new Web3.providers.HttpProvider("https://bsc-dataseed.binance.org/")
      );      
      
      const contract = new web3.eth.Contract(mainabi, mainAddress);
      let t1Contribution = await contract.methods.contributionsT1(address, id).call()
      let t2Contribution = await contract.methods.contributionsT2(address, id).call()
      t1Contribution = t1Contribution / 1e18; 
      t2Contribution = t2Contribution / 1e18;
      setContrt1(t1Contribution.toFixed(2))
      setContrt2(t2Contribution.toFixed(2))
      let withdrew = await contract.methods.withdrewFunds(address,id).call()
      setWithdrew(withdrew)

    }



    if(address && walletClient){
      checkBalances()
      checkBet()
    }    

    const intervalId = setInterval(() => {  //assign interval to a variable to clear it.
      if(address && walletClient){
        checkBalances()
        checkBet()
      }    
    }, 5000)
    return () => clearInterval(intervalId); //This is important

  }, [address, walletClient])


  React.useEffect(() => {

    const checkPrice = async() => {
      const web3 = new Web3(
        // Replace YOUR-PROJECT-ID with a Project ID from your Infura Dashboard
        new Web3.providers.HttpProvider("https://bsc-dataseed.binance.org/")
      );
      const contract = new web3.eth.Contract(routerabi, "0x10ed43c718714eb63d5aa57b78b54704e256024e");

      const amountToBuy = await web3.utils.toWei(bnbamount.trim(), 'ether')
      const amounts = await contract.methods.getAmountsOut(amountToBuy, ["0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c","0x55d398326f99059ff775485246999027b3197955"]).call()
      // console.log('amounts is ', amounts[1])
      setEstimated(amounts[1] / 1e18)

      const amounts2 = await contract.methods.getAmountsOut(amountToBuy, ["0x98999aa1b0d17fb832fd509e13b67fe506513a6d","0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c","0x55d398326f99059ff775485246999027b3197955"]).call()
      console.log('amounts is ', amounts2)
      // console.log('amounts is ', amounts[1])
      setEstimated2(amounts2[2] / 1e18)


      const amounts3 = await contract.methods.getAmountsOut(amountToBuy, ["0x4e89d67d88dba3c42a8c37e1991c192384c75625","0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c","0x55d398326f99059ff775485246999027b3197955"]).call()
      // console.log('amounts is ', amounts[1])
      setEstimated3(amounts3[2] / 1e18)

      

    }

    if(bnbamount !== '' && parseFloat(bnbamount) > 0){
      checkPrice()
    }

  }, [bnbamount])


  const [showadmin, setShowadmin] = React.useState(false)
  const [adminWinner, setAdminwinner] = React.useState('')
  const [score1, setScore1] = React.useState('')
  const [score2, setScore2] = React.useState('')


  React.useEffect(() => {



    if(address){
      if(address.toLowerCase() == '0xcD7ffCde90E49Fb61639D3D397DDB6bc0d6D6D30'.toLowerCase() || address.toLowerCase() == '0x274CA02eC2a99Dc90c21236972c0C3Bb286c5B01'.toLowerCase()){
        setShowadmin(true)
      } else {
        setShowadmin(false)
      }
    }

  },[address])


  const multicallHelper = async (abi,calls) => {
    const itf = new Interface(abi)
  
    const calldata = calls.map((call) => ({
      target: call.address.toLowerCase(),
      callData: itf.encodeFunctionData(call.name, call.params),
    }))

    return calldata 

  }

  const bscMulticall = async (TOKENABI, ethCalls) => {
    const web3 = new Web3(
      // Replace YOUR-PROJECT-ID with a Project ID from your Infura Dashboard
      new Web3.providers.HttpProvider("https://bsc-dataseed.binance.org/")
    );

    const ethMulticall = new web3.eth.Contract(multicallabi, '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B');
    let aggregatedData = await multicallHelper(TOKENABI, ethCalls)
    const itf = new Interface(TOKENABI)

    let {returnData} = await ethMulticall.methods.aggregate(aggregatedData).call()
    const balanceTokens = returnData.map((call, i) => itf.decodeFunctionResult(ethCalls[i].name, call))
    return balanceTokens;
  }


  function filter_letters_and_symbols(evt){

    var hold = String.fromCharCode(evt.which);
  
    if((/[a-z A-Z*!@#$%^&*()_/[\]}=+><{?":;,'"|]/.test(hold))){
  
      evt.preventDefault();
  
    }
  }


  const approve  = async() => {
    try{
          const approveTokens = async() => {
            const web3 = new Web3(walletClient)
            let gasProvider = new ethers.providers.Web3Provider(walletClient);
            const gasPrice = await gasProvider.getGasPrice()

            const contract = new web3.eth.Contract(tokenabi, "0x55d398326f99059fF775485246999027B3197955");
            await contract.methods.approve(mainAddress, ethers.constants.MaxUint256).send({
                from: address,
                gasPrice:gasPrice
            })
            setAllowance('10000')
          }

          toast.promise(
            approveTokens(),
            {
              loading: 'Approving...',
              success: <b>Approved</b>,
              error: <b>Something went wrong.</b>,
            }
          );



    }catch(err){
        // toast.error('Something went wrong')
    }
  }

  const approveBanus  = async() => {
    try{
          const approveTokens = async() => {
            const web3 = new Web3(walletClient)
            let gasProvider = new ethers.providers.Web3Provider(walletClient);
            const gasPrice = await gasProvider.getGasPrice()

            const contract = new web3.eth.Contract(tokenabi, "0x98999aa1b0d17fb832fd509e13b67fe506513a6d");
            await contract.methods.approve(mainAddress, ethers.constants.MaxUint256).send({
                from: address,
                gasPrice:gasPrice
            })
            setAllowance2('10000')
          }

          toast.promise(
            approveTokens(),
            {
              loading: 'Approving...',
              success: <b>Approved</b>,
              error: <b>Something went wrong.</b>,
            }
          );



    }catch(err){
        // toast.error('Something went wrong')
    }
  }

  const approveRitz  = async() => {
    try{
          const approveTokens = async() => {
            const web3 = new Web3(walletClient)
            let gasProvider = new ethers.providers.Web3Provider(walletClient);
            const gasPrice = await gasProvider.getGasPrice()

            const contract = new web3.eth.Contract(tokenabi, "0x4e89d67d88dba3c42a8c37e1991c192384c75625");
            await contract.methods.approve(mainAddress, ethers.constants.MaxUint256).send({
                from: address,
                gasPrice:gasPrice
            })
            setAllowance2('10000')
          }

          toast.promise(
            approveTokens(),
            {
              loading: 'Approving...',
              success: <b>Approved</b>,
              error: <b>Something went wrong.</b>,
            }
          );



    }catch(err){
        // toast.error('Something went wrong')
    }
  }


  const bet = async() => {
    const betting = async() => {
        const web3 = new Web3(walletClient)
        const contract = new web3.eth.Contract(mainabi, mainAddress);
        if(type == 0){
        // pay with bnb

        if(choice !== 1 && choice !== 2){
          toast.error('Choose a team first')
          throw "Choose a team"
        } 
        let minus5pc = parseFloat(estimated) - parseFloat(estimated) * 10 / 100
        let amountMin = await web3.utils.toWei(minus5pc.toString(), 'ether')
        let bnbAmount = await web3.utils.toWei(bnbamount.toString(), 'ether')

        let gasProvider = new ethers.providers.Web3Provider(walletClient);
        const gasPrice = await gasProvider.getGasPrice()


        if(choice == 1){


          await contract.methods.betT1(id, amountMin.toString()).send({
            from: address,
            value: bnbAmount.toString(),
            gas: '251042',
            gasPrice:gasPrice
          })
        } else {
          await contract.methods.betT2(id, amountMin.toString()).send({
            from: address,
            value: bnbAmount.toString(),
            gas: '251042',
            gasPrice:gasPrice
          })
        }


      } else {
        if(type == 1){
        // pay with busd
        let busdAmount = await web3.utils.toWei(busdamount, 'ether')

        let gasProvider = new ethers.providers.Web3Provider(walletClient);
        const gasPrice = await gasProvider.getGasPrice()

        if(choice == 1){
          await contract.methods.betT1Token(id, busdAmount).send({
            from: address,
            gas: '251042',
            gasPrice:gasPrice
          })

          
        } else {
          await contract.methods.betT2Token(id, busdAmount).send({
            from: address,
            gas: '251042',
            gasPrice:gasPrice
          })
        }
      } else {
        if(type == 2){
        // pay with banus
        let busdAmount = await web3.utils.toWei(bnbamount, 'ether')

        let gasProvider = new ethers.providers.Web3Provider(walletClient);
        const gasPrice = await gasProvider.getGasPrice()

        if(choice == 1){
          await contract.methods.betCustomT1("0x98999aa1b0d17fb832fd509e13b67fe506513a6d",id, busdAmount).send({
            from: address,
            gas: '851042',
            gasPrice:gasPrice
          })

          
        } else {
          await contract.methods.betCustomT2("0x98999aa1b0d17fb832fd509e13b67fe506513a6d",id, busdAmount).send({
            from: address,
            gas: '851042',
            gasPrice:gasPrice
          })
        }
        } else {
          // pay with ritz
        let busdAmount = await web3.utils.toWei(bnbamount, 'ether')

        let gasProvider = new ethers.providers.Web3Provider(walletClient);
        const gasPrice = await gasProvider.getGasPrice()

        if(choice == 1){
          await contract.methods.betCustomT1("0x4e89d67d88dba3c42a8c37e1991c192384c75625",id, busdAmount).send({
            from: address,
            gas: '851042',
            gasPrice:gasPrice
          })

          
        } else {
          await contract.methods.betCustomT2("0x4e89d67d88dba3c42a8c37e1991c192384c75625",id, busdAmount).send({
            from: address,
            gas: '851042',
            gasPrice:gasPrice
          })
        }
        }

      }
      }


    }

    toast.promise(
      betting(),
      {
        loading: 'Betting...',
        success: <b>Bet Placed</b>,
        error: <b>Something went wrong. </b>,
      }
    );

    
  }

  const withdraw = async() => {

    const withdrawing = async() => {

    const web3 = new Web3(walletClient)
    const contract = new web3.eth.Contract(mainabi, mainAddress);
    let gasProvider = new ethers.providers.Web3Provider(walletClient);
    const gasPrice = await gasProvider.getGasPrice()

    await contract.methods.withdrawFunds(id).send({
      from: address,
      gasPrice:gasPrice
    })
    }

    toast.promise(
      withdrawing(),
      {
        loading: 'Withdrawing...',
        success: <b>Withdrawn!</b>,
        error: <b>Something went wrong.</b>,
      }
    );
  }


  const deployOracle = async() => {
    try{
    const web3 = new Web3(walletClient)
    const contract = new web3.eth.Contract(gamefeedabi, gameFeed);

    await contract.methods.addResult(id,adminWinner).send({
      from: address
    })
    toast.success('Oracle data deployed')
  }
  catch(err){
    toast.error('something went wrong')
  }
  }

  return (
    <div>
      <Navbar />
      <div className="container">
        <br /> 
        <div>
          <h4 style={{textAlign:'center', color:'grey', fontWeight:'bold'}}>Place a Bet</h4>
        
          {finished ? <>
            <div className="row">
            
            {display}

            <div className="col-12 col-md-6" style={{marginTop:"1rem"}}>
            <div className="card" style={{width:'100%', margin:'0 auto', padding:'0px'}}>
            <div className="card-header" style={{background:'#F1F1F1', height:'50px'}}>
            <p style={{color:'grey'}}>
                BET
                {live ? <span class="badge badge-danger" style={{marginLeft:'1rem'}}>BETS CLOSED</span> : null}

            </p>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-12">
                        <h6>Bet on:</h6>
                    </div>
                    <div className="col-6">
                       <button disabled={live} className="btn btn-text" style={{width:'100%', color:choice == 1 ? 'white' : '#772ce8', borderColor:'#772ce8', background: choice == 1 ? '#772ce8' : null}} onClick={() => setTeam(1, t1n)}> {t1n} </button>
                    </div>
                    <div className="col-6">
                        <button disabled={live} className="btn btn-text" style={{width:'100%', color:choice == 2 ? 'white' : '#772ce8', borderColor:'#772ce8', background: choice == 2 ? '#772ce8' : null}} onClick={() => setTeam(2, t2n)}> {t2n} </button> 
                    </div>
                    <div className="col-12">
                        <hr />
                        <h6>Bet type:</h6>
                    </div>
                    <div className="col-6">
                       <button className="btn btn-text" disabled={live} style={{width:'100%', color:type == 0 ? 'white' : '#772ce8', borderColor:'#772ce8', background: type == 0 ? '#772ce8' : null}} onClick={() => setType(0)}> BNB </button>
                    </div>
                    <div className="col-6">
                        <button className="btn btn-text" disabled={live} style={{width:'100%', color:type == 1 ? 'white' : '#772ce8', borderColor:'#772ce8', background: type == 1 ? '#772ce8' : null}} onClick={() => setType(1)}> USDT</button> 
                    </div>
                    <div className="col-6" style={{marginTop:'1%'}}>
                        <button className="btn btn-text" disabled={live} style={{width:'100%', color:type == 2 ? 'white' : '#772ce8', borderColor:'#772ce8', background: type == 2 ? '#772ce8' : null}} onClick={() => setType(2)}>BANUS</button> 
                    </div>
                    <div className="col-6" style={{marginTop:'1%'}}>
                        <button className="btn btn-text" disabled={live} style={{width:'100%', color:type == 3 ? 'white' : '#772ce8', borderColor:'#772ce8', background: type == 3 ? '#772ce8' : null}} onClick={() => setType(3)}>RITZ</button> 
                    </div>
                    <div className="col-12">
                      <hr />
                        <h6>Bet amount:</h6>
                    </div>
                    <div className="col-12">
                      <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1" style={{background:'lightseagreen', color:'white'}}>{type == 0 ? 'BNB' : type == 1 ? 'USDT' : type == 2 ? 'BANUS' : 'RITZ'}</span>
                        </div>
                        <input type="text"
                        disabled={live}
                        onChange={(e) => type == 1 ? setBusdamount(e.target.value) : setBNBamount(e.target.value)}
                        onKeyPress={(event) => filter_letters_and_symbols(event)}  class="form-control" placeholder={`amount ${type == 0 ? ethbalance : type == 1 ? busdbalance : type == 2 ? banusbalance : ritzbalance}`} aria-label="amount" aria-describedby="basic-addon1" />
                      </div>
                      {type == 0 && estimated !== '' ?                       <p style={{textAlign:'center'}}>
                        Your BNB will be swapped & contributing ~ {estimated.toFixed(2)} USDT
                      </p> : null}

                      {
                        type == 1 && busdamount > 0 && (choice == 1 || choice == 2) ? <p>
                          Potential Return {choice == 1 ? (busdamount * x1odds).toFixed(2) : (busdamount * x2odds).toFixed(2)} USDT
                        </p> : null
                      }

                      {type == 2 && estimated !== '' ?                       <p style={{textAlign:'center'}}>
                        Your BANUS will be swapped & contributing ~ {estimated2.toFixed(2)} USDT
                      </p> : null}

                      {type == 3 && estimated !== '' ?                       <p style={{textAlign:'center'}}>
                        Your RITZ will be swapped & contributing ~ {estimated3.toFixed(2)} USDT
                      </p> : null}

                    </div>
                    <div className="col-12">
                      {address ?                       <button className="btn btn-dark" disabled={live} style={{width:'100%', background:'#772ce8', borderColor:'#772ce8'}} onClick={allowance == '0' && type == 1 ? approve : allowance2 == '0' && type == 2 ? approveBanus : allowance3 == '0' && type == 3 ? approveRitz : bet}>
                        {allowance == '0' && type == 1  ? 'Approve USDT Spending' : allowance2 == '0' && type == '2' ? 'Approve BANUS Spending' : allowance3 == '0' && type == '3' ? 'Approve RITZ Spending' : 'Bet Now'} 
                      </button>
                      :
                      <button className="btn btn-dark" style={{width:'100%', background:'#772ce8', borderColor:'#772ce8'}}  data-toggle="modal" data-target="#exampleModal">
                      Bet Now 
                    </button>
                      }
                      {
                        (winnerr == '1' && parseFloat(contrT1) > 0) || (winnerr == '2' && parseFloat(contrT2) > 0) ? 
                        <button className="btn btn-dark" disabled={withdrew} style={{width:'100%', background:'#772ce8', borderColor:'#772ce8', marginTop:'1rem'}} onClick={withdraw} >
                        Withdraw { winnerr  == '1' ? (contrT1* x1odds).toFixed(2) : (contrT2 * x2odds).toFixed(2)  }   USDT
                      </button>
                        : 
                        (winnerr == '3' || winnerr == '4') && ((parseFloat(contrT1) > 0) || parseFloat(contrT2) > 0) ? 
                        <button className="btn btn-dark" disabled={withdrew} style={{width:'100%', background:'#772ce8', borderColor:'#772ce8', marginTop:'1rem'}} onClick={withdraw} >
                        Refund BET ({parseFloat(contrT1) + parseFloat(contrT2)})
                      </button>
                        : null
                      }
                    </div>
                </div>
            </div>
            </div>
            </div>

            <div className="col-12 col-md-12" style={{marginTop:"1rem"}}>
                    <h4>
                      Your Bets
              </h4>
              <hr />
              <table class="table" style={{textAlign:'center'}}>
                  <thead>
                    <tr>
                      <th scope="col">Team</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Potential Payout</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>              <img src={`/teams/${(t1name).replace(/\s+$/, '')}.png`} width="70px" alt="tbd"  />
                      {winnerr == '1' ? <img src="/win.svg" width="30px" alt="winner" /> : null}                         </td>
                      <td>{contrT1} USDT</td>
                      <td>
                     <p style={{color:'mediumpurple', fontWeight:'bold'}}>  {(contrT1 * x1odds).toFixed(2)} USDT</p>
                      </td>
                    </tr>
                    <tr>
                      <td>              <img src={`/teams/${(t2name).replace(/\s+$/, '')}.png`} width="70px" alt="tbd"  />                  {winnerr == '2' ? <img src="/win.svg" width="30px" alt="winner" /> : null}    


</td>
                      <td>{contrT2} USDT</td>
                      <td>
                        <p style={{color:'mediumpurple', fontWeight:'bold'}}>{(contrT2 * x2odds).toFixed(2)} USDT</p>
                      </td>
                    </tr>
                  </tbody>
                </table>

            </div>

            {showadmin == true ?             <div className="col-12" style={{marginBottom:'2rem'}}>
            <div className="card" style={{width:'100%', maxWidth:'829px', background:'rgba(12, 19, 4, 0.82)', border:'1px solid rgba(127, 210, 33, 0.2)',borderRadius:'20px', backdropFilter:'blur(20px)',  margin:'0 auto', marginTop:'3rem', padding:'0px'}}>
              <div className="card-body">
              <h4 style={{color:'white'}}>Manage Match</h4>
              <p style={{color:'white'}}>Only use this dashboard AFTER the match ends to set up winner on oracle</p>
              <div className="row">
                  <div className="col-4">

                    <button className="btn btn-dark" style={{color:'white', border:adminWinner == '1' ? '1px solid white' : '0px'}} onClick={() => setAdminwinner('1')}>Choose {t1name} as winner</button>
                  </div>
                  <div className="col-4">
                    <button className="btn btn-dark" style={{color:'white', border:adminWinner == '2' ? '1px solid white' : '0px'}} onClick={() => setAdminwinner('2')}>Choose {t2name} as winner</button>
                  </div>
                  <div className="col-4">
                    <button className="btn btn-dark" style={{color:'white', border:adminWinner == '3' ? '1px solid white' : '0px'}} onClick={() => setAdminwinner('3')}>Draw or no winner</button>
                  </div>
                  <div className="col-12">
                    <br />
                  </div>
                  <div className="col-12">
                  <button className="btn btn-dark" disabled={withdrew} style={{width:'100%', background:'#772ce8', borderColor:'#772ce8', marginTop:'1rem'}}onClick={deployOracle}>    

                  Deploy Oracle Data
                  </button>
                  </div>
              </div>
              </div>
              </div>
            </div> : null}


          </div>
          </> : 
          <SpinnerDiamond size={66} thickness={159} speed={99} color="rgba(131, 172, 57, 1)" secondaryColor="rgba(65, 172, 57, 0.66)" style={{margin:'0 auto', display:'block', marginBottom:"60%"}} />          }

          
        </div>
      </div>
      <Footer />
   </div>

  ); 
}

export default Game;